import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SingleColumnLayout from 'components/templates/SingleColumnLayout';
import SimpleSection from 'components/organisms/SimpleSection';
import ChoiceList from 'components/molecules/ChoiceList';
import { ChoiceItem } from 'components/molecules/Choice';
import chosenCompanyVar from 'utils/graphql/localState/chosenCompany';
import { fetchCompanies } from 'services/companyService';
import { AxiosResponse } from 'axios';
import { CustomError } from 'utils/api/error';
import { Company } from 'models/company';
import { CompanyContext } from 'contexts/CompanyContext';
import { isDevEnv } from 'utils/helpers';
import { Card } from '@urbansportsclub/components/Card';
import { Text } from '@urbansportsclub/components/Text';
import { LockClosedIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { UserContext } from 'contexts/UserContext';
import { Alert } from '@urbansportsclub/components/Alert';
import { AlertTitle } from '@urbansportsclub/components/Alert/Title';
import { ORGANIZATION_OVERVIEW } from 'utils/constants';

const ONEFIT_URL = isDevEnv() ? 'https://one.fit/business/' : 'https://one.fit/business/';
const USC_URL = isDevEnv() ? 'https://urbansportslclub.com/' : 'https://urbansportsclub.com/';

const CompanyChoice = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [companyChoiceItems, setCompanyChoiceItems] = useState<ChoiceItem[]>([]);
  const { setCompany } = useContext(CompanyContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    fetchCompanies().then(
      (response: AxiosResponse<Company[]> | CustomError) => {
        const items: ChoiceItem[] = [];
        const getSlug = (name: string) => name.replace(USC_URL, '').replace(ONEFIT_URL, '');

        if ('data' in response) {
          response.data.forEach((company: Company) => {
            items.push({
              uuid: company.id,
              id: company.id,
              text: company.name,
              integrationName: company.integrationName,
              isOnboardingQuestionsEnabled: company.isOnboardingQuestionsEnabled,
              hrIntegrationName: company.hrIntegrationName,
              hrIntegrationLastSyncedAt: company.hrIntegrationLastSyncedAt,
              isReportsEnabled: company.featureFlags.isReportsEnabled,
              company,
            });
            
            // Show new badge for companies with the same employeeSignUpUrl
            const sameCompanies = items.filter((item) => {
              if (!item.company.employeeSignUpUrl || !company.employeeSignUpUrl) return false;

              return getSlug(item.company.employeeSignUpUrl) === getSlug(company.employeeSignUpUrl);
            });

            if (sameCompanies.length > 1) {
              const uscCompany = sameCompanies.find((item) => item.integrationName.indexOf('usc') !== -1);
              if (uscCompany) {
                const index = items.findIndex((item) => item.id === uscCompany.id);
                items[index].showNewBadge = true;
              }
            }
            // End of new badge logic

          });
          setCompanyChoiceItems(items);
        }

        if (items.length === 1) {
          handleChoose(items[0]);
        }
      },
    );
  }, []);

  const handleChoose = (item: ChoiceItem) => {
    chosenCompanyVar.init({
      uuid: item.uuid,
      id: item.id,
      name: item.text,
      integrationName: item.integrationName,
      isOnboardingQuestionsEnabled: item.isOnboardingQuestionsEnabled,
      hrIntegrationName: item.hrIntegrationName,
      hrIntegrationLastSyncedAt: item.hrIntegrationLastSyncedAt,
      isReportsEnabled: item.isReportsEnabled,
    });

    setCompany(item.company);

    navigate('/home');
  };

  const handleOrganizationChoise = () => {
    chosenCompanyVar.init({
      uuid: 'organization',
      id: 'organization',
      name: 'Organization Overview',
      integrationName: 'organization',
      isOnboardingQuestionsEnabled: false,
    });

    setCompany(ORGANIZATION_OVERVIEW);

    navigate('/enterprise-reports');
  };

  return (
    <SingleColumnLayout>
      { user && user.isOrganisationEnabled && companyChoiceItems.length > 1 && (
        <>
          <Card className='!shadow-none bg-white-natural-500 p-7 border-blue-respect-500 max-w-[400px] mx-auto mb-4 flex gap-x-4 cursor-pointer' onClick={handleOrganizationChoise}>
            <LockClosedIcon className='w-6 h-6 text-blue-respect-500' />
            <Text as='span' intent='lg' weight='bold'>{t('report_page.organization_overview')}</Text>
            <ArrowRightIcon className='w-6 h-6 text-black-midnight-light-800 ml-auto'/>
          </Card>
          <Alert severity='info' showCloseButton={false} className='p-4 mb-4 max-w-[400px] mx-auto'>
            <AlertTitle>{t('report_page.info')}</AlertTitle>
          </Alert>
        </>
      )}
      <SimpleSection title={t('company_choice.title')}>
        <ChoiceList items={companyChoiceItems} onChoose={handleChoose} />
      </SimpleSection>
    </SingleColumnLayout>
  );
};

export default CompanyChoice;
