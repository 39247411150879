import { Company } from 'models/company';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useGetCompany } from 'services/companyService';
import { ORGANIZATION_OVERVIEW } from 'utils/constants';
import { ChosenCompany } from 'utils/graphql/localState/chosenCompany';
import { capitalizeFirstLetter } from 'utils/helpers';

export interface CompanyContextProps {
  company?: Company;
  showSyncing: boolean;
  setCompany: (company?: Company) => void;
  setPolling: (polling: boolean) => void;
  setShowSyncing: (showSyncing: boolean) => void;
  reloadCompany?: () => void;
}

export const CompanyContext = createContext<CompanyContextProps>({
  company: {} as Company,
  setCompany: () => { },
  setPolling: () => { },
  showSyncing: false,
  setShowSyncing: () => { },
  reloadCompany: () => { },
});

export function CompanyProvider({ children }: { children: React.ReactNode }) {
  const [company, setCompany] = useState<Company | undefined>();
  const [polling, setPolling] = useState(false);
  const [showSyncing, setShowSyncing] = useState(false);
  const { getCompany, data } = useGetCompany();
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('chosenCompany') && localStorage.getItem('authToken') && !company) {
      const choosenCompany = JSON.parse(localStorage.getItem('chosenCompany') as string) as ChosenCompany;

      if (choosenCompany.id == ORGANIZATION_OVERVIEW.id) {
        setCompany(ORGANIZATION_OVERVIEW);
      } else {
        getCompany(choosenCompany.uuid);
      }
    }
  }, []);

  useEffect(() => {
    if (data) {
      changeCompany(data.company);
    }
  }, [data]);

  useEffect(() => {
    setSyncing();
    sendSyncNotification();
  }, [company]);

  useEffect(() => {
    if (polling) {
      const interval = setInterval(() => {
        const choosenCompany = JSON.parse(localStorage.getItem('chosenCompany') as string) as ChosenCompany;
        getCompany(choosenCompany.uuid);
      }, 60000);
      setIntervalId(interval);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
  }, [polling]);

  function sendSyncNotification() {
    if (polling && company?.hrIntegrationLastSyncedAt) {
      toast(t('hr_integration.toast.connected')
        .replace('{{integration_name}}', capitalizeFirstLetter(company?.hrIntegrationName || '')));
      setPolling(false);
      setShowSyncing(false);
    }
  }

  function setSyncing() {
    if (!company?.hrIntegrationLastSyncedAt && company?.isHrIntegrationConnected && !polling) {
      setPolling(true);
      setShowSyncing(true);
    }
  }

  function disableSyncing() {
    setShowSyncing(false);
    if (intervalId) {
      clearInterval(intervalId);
    }
  }

  function changeCompany(incomingCompany?: Company) {
    if (incomingCompany?.id !== company?.id) {
      disableSyncing();
    }
    setCompany(incomingCompany);
  }

  function reloadCompany() {
    getCompany(company?.id || '');
  }

  return (
    <CompanyContext.Provider value={{ company, setCompany: changeCompany, setPolling, setShowSyncing, showSyncing, reloadCompany }}>
      {children}
    </CompanyContext.Provider>
  );
}