import React from 'react';
import { Card } from '@urbansportsclub/components/Card';
import { ArrowDownTrayIcon, ArrowTrendingUpIcon } from '@heroicons/react/24/solid';
import { Button } from '@urbansportsclub/components/Button';
import { Text } from '@urbansportsclub/components/Text';
import { EnterpriseReport } from 'models/report';
import { formatMonthYear } from 'utils/date';
import { Badge } from '@urbansportsclub/components/Badge';
import { useDownloadOrganisationReports } from 'services/reportService';
import { useTranslation } from 'react-i18next';

interface IReportCard {
  data: EnterpriseReport,
  isLatest?: boolean,
} 

const ReportCard = ({ data, isLatest = false } : IReportCard) => {
  const { t } = useTranslation();
  const { downloadOrganisationReport } = useDownloadOrganisationReports();

  const handleDownloadReport = () => {
    downloadOrganisationReport(data.year, data.month);
  };
 
  return (
    <Card className='px-4 md:px-6 py-4 !shadow-none rounded-xl flex justify-between items-center bg-white-natural-500 gap-x-4'>
      <div className=''>
        <div className='flex items-center gap-x-2 mb-2'>
          <Text as='h4' intent='md' weight='bold'>{formatMonthYear(new Date(Number(data.year), Number(data.month)))}</Text>
          { isLatest && <Badge mode='dark'>{t('report_page.latest')}</Badge> }
        </div>
        <div className='flex items-center'>
          <div className='max-[375px]:max-w-[170px] sm:min-w-[200px] flex items-center gap-x-2 md:gap-x-4 max-sm:mr-2'>
            <Text as='span' intent='sm' className='inline-block text-ellipsis	whitespace-nowrap	overflow-hidden'>{data.activeMemberships} {t('report_page.active_members')}</Text>
            { data.newMemberships > 0 && (
              <Badge variant='secondary' className='gap-x-1'>
                <span className='pt-[1px]'>{data.newMemberships}</span>
                <ArrowTrendingUpIcon className='h-4 w-4 text-[#2EBA88]' />
              </Badge> 
            )}
          </div>
          <Text as='span' intent='sm' className='sm:min-w-[200px] inline-block text-center border-l border-l-[1px] whitespace-nowrap max-sm:pl-2'>{data.pausedMemberships} {t('report_page.mobile_paused_members')}</Text>
        </div>
      </div>
      <div>
        <Button className='text-sm bg-transparent p-0 h-[auto]' variant='link' onClick={handleDownloadReport}>
          <span className='max-md:hidden'>{t('report_page.download')}</span> <ArrowDownTrayIcon className='w-5 h-5 md:h-6 md:w-6'/>
        </Button>
      </div>
    </Card>
  );
};

export default ReportCard;
